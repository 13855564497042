<template>
  <v-data-table
    dense
    :headers="headers"
    :items="items"
    :items-per-page="15"
  ></v-data-table>
</template>

<script>
export default {
  name: 'DrillPlan',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Views', value: 'viewCount' },
    ],
  }),
};
</script>
